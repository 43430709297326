<template>
  <div>
    <b-row>
      <b-col cols="9" style="margin-left: -2px;"></b-col>
      <b-col cols="3" class="d-flex justify-content-end" v-if="!viewOnly">
        <a
          class="btn btn-primary font-weight-bolder font-size-sm"
          style="max-height: 38px;"
          @click="createParent"
        >
          <i class="menu-icon flaticon2-plus pr-0 pr-md-2" style="font-size: 1em"></i
          ><span class="d-none d-md-inline-block">Lägg till</span>
        </a>
      </b-col>
    </b-row>

    <hr class="my-3" />

    <b-row>
      <b-table 
        v-if="items && items.length > 0" 
        class="table-striped"
        responsive 
        small 
        :fields="fields" 
        :items="parents" 
        :tbody-tr-class="rowClass">
        <template #cell(action)="data">
          <div class="text-right" v-if="!viewOnly">
            <a
              class="btn btn-icon btn-light btn-sm mx-2"
              v-if="data.item.id !== member.member_id"
              @click="editFA(data.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a
              class="btn btn-icon btn-light btn-sm mx-2"
              v-if="data.item.id !== member.member_id && !data.item.head"
              @click="deleteFA(data.item.item_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      <div v-if="items.length === 0" class="alert alert-custom alert-notice alert-light-primary fade show" style="width: 100%;" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">{{$t('MEMBER.NO_GUARDIANS')}}</div>
      </div>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios';
import RegisterFamilyForm from '@/view/components/RegisterFamilyForm.vue';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ParentsTable',
  components: {
    RegisterFamilyForm
  },
  mixins: [ toasts ],
  props: ['member', 'parents', 'viewOnly'],
  emits: ['createParent'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  data() {
    return {
      idCounter: 0,
      fields: [
        { key: 'fn', label: this.$t('MEMBER.FIRSTNAME') },
        { key: 'ln', label: this.$t('MEMBER.LASTNAME') },
        { key: 'em', label: this.$t('MEMBER.EMAIL') },
        { key: 'ph', label: this.$t('MEMBER.MOBILE') },
        { key: 'pn', label: this.$t('MEMBER.PERSONNR') },
        { key: 'action', label: '' }
      ],
      items: [],
      data: null,
      testData: [
        {
          fn: 'test1',
          ln: 'lastname',
          em: 'email@email.com',
          ph: '08234234',
          id: 'aaa',
          pn: '823211-1231',
          head: false
        }
      ]
    };
  },
  watch: {
    member(newValue, oldValue) {
      if (newValue) {
        this.loadParentsMembers();
      }
    }
  },
  mounted() {},
  methods: {
    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.head) {
          return 'font-weight-bolder';
        }

        if (item.is_created) {
          return 'text-success';
        }
      }

      return null;
    },
    deleteFA(id) {
      // delete all parents for member with member_id?
      axios
        .delete(`/member/parents/${id}`)
        .then(res => {
          this.items = this.items.filter(item => item.id !== id);

          this.toastr('success', this.$t('COMMON.OK'), 'Vårdnadshavaren');
        })
        .catch(err => {
          console.error(err);

          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort vårdnadshavare');
        });
    },
    editFA(parentsmember) {},
    saveParentsMember() {},
    createParent() {
      this.$emit('createParent');
    },

    loadParentsMembers() {

      axios
        .get(`/member?fid=${this.member.fid}`)
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta vårdnadshavare');
        });
    }
  }
};
</script>
<style scoped>
#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}
#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}
#bar button {
  min-width: 80px;
  cursor: pointer;
}
</style>
