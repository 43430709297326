<template>
  <div>

    <b-row v-if="search_family_member_visible">
      <b-col>
        <b-row class="d-flex justify-content-end mr-0">
            <b-button variant="outline-primary" @click="cancel_search_member">{{$t('COMMON.CANCEL')}}</b-button>
        </b-row>
        <b-row>
          <b-col>
            <QuickSearchMemberWidget @member_selected="add_family_member"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row v-if="!search_family_member_visible">
      <b-col cols="9" style="margin-left: -2px;"></b-col>
      <b-col cols="3" class="d-flex justify-content-end" v-if="!viewOnly">
        <a class="btn btn-primary font-weight-bolder font-size-sm" style="max-height: 38px;" @click.prevent="search_family_member">
          <i class="menu-icon flaticon2-plus pr-0 pr-md-2" style="font-size: 1em"></i>
          <span class="d-none d-md-inline-block">{{$t('COMMON.ADD')}}</span>
        </a>
      </b-col>
    </b-row>

    <hr class="my-3" />

    <b-row>
      <b-table 
        v-if="items && items.length > 0" 
        class="table-striped" 
        responsive 
        small 
        :fields="fields" 
        :items="items" 
        :tbody-tr-class="rowClass">
        <template #cell(member_id)="data">

          {{( data.item.id === member.member_id ? $t('COMMON.YES') : $t('COMMON.NO'))}}

        </template>

        <template #cell(head)="data">
          <!--input type="radio" name="main_member" :checked="data.item.head" @input="set_head_member(data.index)"/-->
          <input type="radio" name="main_member" v-model="selected_head_index" :value="data.index" @input="set_head_member(data.index)"/>
        </template>

        <template #cell(action)="data">
          <div class="text-right" v-if="!viewOnly">
            <a class="btn btn-icon btn-light btn-sm mx-2" @click="goto_family_member(data.item.member_id)">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-user color-primary" style="color: #3699FF"></i>
              </span>
            </a>

            <!--dummy icon instead of delete for head member-->
            <a v-if="data.item.head" class="btn btn-icon btn-light btn-sm mx-2 disabled">
              <span class="svg-icon svg-icon-md svg-icon-primary"></span>
            </a>

            <a class="btn btn-icon btn-light btn-sm mx-2" v-if="!data.item.head" @click.prevent="remove_family_member(data.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>

      <div v-if="items.length === 0" class="alert alert-custom alert-notice alert-light-primary fade show" style="width: 100%;" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('MEMBER.NO_FAMILY')}}</div>
      </div>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios';
import QuickSearchMemberWidget from '@/view/pages/ml/search/QuickSearchMemberWidget.vue';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FamilyTable',
  mixins: [ toasts ],
  components: {
    QuickSearchMemberWidget
  },
  props: ['member', 'settings', 'regions', 'me', 'viewOnly', 'is_member'],
  emits: ['on_family_data_changed'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies']),
  },
  data() {
    return {
      idCounter: 0,
      fields: [
        { key: 'head', label: this.$t('MEMBER.HD') },
        { key: 'firstname', label: this.$t('MEMBER.FIRSTNAME') },
        { key: 'lastname', label: this.$t('MEMBER.LASTNAME') },
        { key: 'email', label: this.$t('MEMBER.EMAIL') },
        { key: 'action', label: '' }
      ],
      items: [],
      search_family_member_visible: false,
      selected_head_index : 0
    };
  },
  watch: {
    'member'(new_value) {
      // member is changed then clear and reload
      this.items = [];
      if (new_value.member_id) {
        // only reload when member is valid
        this.load();
      }
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (!this.member.fid || this.member.fid === 0 || this.member.fid < 0) {
        return;
      }

      const url = this.is_member ? `/member_relation/member` : `/member_relation/fid/${this.member.fid}`;

      axios
        .get(url)
        .then(res => {
          if (res.status === 200) {
            this.update_family_list(res.data);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_LIST_FAMILY'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_LIST_FAMILY'));
        });
    },

    add_family_member(family_member) {
      this.search_family_member_visible = false;
      const head_member_id = this.items.length > 0 ? this.items[0].member_id : this.member.member_id;
      axios
        .post(`/member_relation`, {
          member_id: head_member_id,
          rel_member_id: family_member.member_id,
          relation: "FAMILY"
        })
        .then(res => {
          if (res.status === 201) {
            const item = res.data;
            const is_first_relation = this.items.length == 0;
            if (is_first_relation) {
              // if list is empty add head member first
              this.items.push(
                {
                  head: true,
                  member_id: item.member.member_id,
                  firstname: item.member.firstname,
                  lastname: item.member.lastname,
                  email: item.member.email,
                });
            }
            this.items.push(
              {
                id: item.id,
                head: false,
                member_id: item.rel_member.member_id,
                firstname: item.rel_member.firstname,
                lastname: item.rel_member.lastname,
                email: item.rel_member.email,
              });
            if (is_first_relation) {
              this.$emit('on_family_data_changed');
            }
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.ADD_FAMILY_MEMBER'));
          }
          else if (res.status === 406) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_ADD_MEMBER_IN_ANOTHER_FAMILY'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_ADD_FAMILY_MEMBER'));
          }
        })
        .catch(err => {
          console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_ADD_FAMILY_MEMBER'));
        });
    },

    remove_family_member(id){
      axios
        .delete(`/member_relation/${id}`)
        .then(res => {
          if (res.status === 204) {
            this.items = this.items.filter(item => item.id !== id);
            if (this.items.length < 2) {
              // if last member is removed there are no relations left so the list must be cleared
              this.items = [];
            }
            this.$emit('on_family_data_changed');
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.FAMILY_REMOVED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_FAMILY_REMOVE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_FAMILY_REMOVE'));
        });

    },

    set_head_member(index) {
      const fid = this.member.fid
      const head_member_id = this.items[index].member_id;
      axios
        .put(`/member_relation/hd/${fid}/${head_member_id}`)
        .then(res => {
          if (res.status === 200) {
            this.update_family_list(res.data);
            this.$emit('on_family_data_changed');
            this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.UPDATE_HD'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_HD'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_HD'));
        });
    },

    update_family_list(data) {
      this.items = [];
      this.selected_head_index = 0;
      for (var i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.relation !== "FAMILY") { continue; }
        if (this.items.length === 0) {
          this.items.push(
            {
              head: true,
              member_id: item.member.member_id,
              firstname: item.member.firstname,
              lastname: item.member.lastname,
              email: item.member.email,
            });
        }
        this.items.push(
          {
            id: item.id,
            head: false,
            member_id: item.rel_member.member_id,
            firstname: item.rel_member.firstname,
            lastname: item.rel_member.lastname,
            email: item.rel_member.email,
          });
      }
    },

    search_family_member() {
      this.search_family_member_visible = true;
    },

    cancel_search_member() {
      this.search_family_member_visible = false;
    },

    goto_family_member(member_id) {
      window.location = `/ml-member-editor/${member_id}`;
    },

    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.head) {
          return 'font-weight-bolder';
        }

        if (item.is_created) {
          return 'text-success';
        }
      }
      return null;
    }
  }
};
</script>

<style scoped>
:deep .table > tbody > tr > td {
  vertical-align: middle;
}
#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}
#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}
#bar button {
  min-width: 80px;
  cursor: pointer;
}
</style>
